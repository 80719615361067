<template>
  <ul class="accordion">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: "accordion",
    props: {},
    data() {
      return {
        Accordion: {
          count: 0,
          active: null
        }
      };
    },
    provide() {
      return { Accordion: this.Accordion };
    }
}
</script>

<style lang="scss" scoped>
.accordion {
  list-style: none;
  /*margin: 0;
  padding: 0;*/

  /*width: 50.625vw;
  height: 22.135vw;*/
  /*
  border: 0.052vw solid #FFFFFF;
  */
  /*
  margin-bottom: 2.083vw;
  */
  font-size: 1.354vw;
  font-family: 'Benzin-Regular';
  color: #F5E9E9;
  /*padding-top: 1.042vw;
  padding-left: 1.563vw;*/

  /*&__item:last-child {
    border-bottom: none;
  }*/
}
@media screen and(max-width: 991px) {

}

</style>